// Override default variables before the import
$theme-colors: (
	'primary': #36a7b9,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

@font-face {
	font-family: 'Fugaz One';
	src: url('../fonts/FugazOne-Regular.eot'); /* IE9 Compat Modes */
	src: url('../fonts/FugazOne-Regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/FugazOne-Regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/FugazOne-Regular.woff') format('woff'),
		/* Pretty Modern Browsers */ url('../fonts/FugazOne-Regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/FugazOne-Regular.svg#FugazOne') format('svg'); /* Legacy iOS */
}
